.delete-popup {
    font-size: 1.5rem;
}
.delete-popup .dependencies {
    margin: 10px 0 20px 0;
    padding: 10px;
    border: 1px solid darkred;
    border-radius: 10px;
    text-align: start;
    overflow: hidden;
}

.delete-popup .dependencies .page-title {
    font-size: 1.7rem;
    color: navy;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    grid-column-gap: 10px;
    margin-top: 10px;
}

.delete-popup .dependencies .page-title .col-title {
    overflow: hidden;
    text-overflow:ellipsis ;
    white-space: nowrap;
    /*max-width: 100px;*/
}

.delete-popup .dependencies .rows-data {
    display: grid;
    grid-template-columns: 0.55fr 0.3fr 1fr 0.6fr 1fr;
    grid-column-gap: 10px;
}
.delete-popup .dependencies .rows-data-pg {
    display: grid;
    grid-template-columns: 0.2fr 0.3fr 1fr;
    grid-column-gap: 10px;
}
.delete-popup .dependencies  .rows-data.header,
.delete-popup .dependencies  .rows-data-pg.header {
    background: lightcyan;
    color: black;
    text-align: left;
    height: auto;
}
.delete-popup .dependencies  .rows-data .col.type,
.delete-popup .dependencies  .rows-data-pg .col.type {
    padding-left: 20px;
}

.delete-popup .dependencies .col {
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding: 5px;
}

.delete-popup  .toolbox.btn-2 {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
    margin-top: 20px;
}

.delete-popup  .toolbox.warning {
    color: chocolate;
    margin-bottom: 20px;
}

