body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svd-page.svd_selected_page {
  background-color: #daecff !important;
}

.svd-property-editor-nested-items div {
  max-height: unset !important;
}

.svd_commercial_container {
  display: none;
}

.survey-editor {
  --primary-color: #4a8ed5;
  --main-theme-btn-background-color: #4a8ed5;
  min-width: 1000px;
}

.header {
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  background: rgb(247, 226, 226);
  color: white;
  height: 38px;
  margin: auto;
  line-height: normal;
  justify-content: center;
  align-items: center;
  min-width: 800px;
}
.header .logo {
  padding-right: 10px;
}

.header .title {
  font-size: 2rem;
  font-weight: 200;
  color: rgb(22, 22, 22);
  text-shadow: 0px 0px 2px #808080ff;
}

.svd_container .btn {
  border-radius: 5px !important;
  font-size: 1.4rem !important;
}

.svd_content {
  background-color: white !important;
}

.sv_technical.svda-add-new-item,
.svda-select-items-editor .svda-select-items-title {
  display: none !important;
}

.svd-page {
  font-weight: 100;
  font-size: 1.5rem;
}

.survey-editor .noUi-connect {
  background-color: var(--primary-color);
}

.nouislider_header {
  display: flex;
  justify-content: space-between;
  padding: 44px 20px 19px 20px;
}

.noUi-marker:first-child + .noUi-value {
  text-align: left;
  transform: translate(0%, 50%);
}
.noUi-value:last-child {
  text-align: right;
  transform: translate(-100%, 50%);
}

select {
  line-height: normal !important;
}

.no-data-available {
  width: 160px;
  border-radius: 5px !important;
  margin-top: 10px;
}

.no-data-available:disabled {
  background: rgb(206, 206, 206);
}

.header-warning {
  padding: 10px;
  background: #fedfd3;
  display: flex;
  margin: 10px auto;
  max-width: 1000px;
  border-radius: 10px;
}

.toc_list button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.template-info {
  padding: 20px 0 0 30px;
}

.template-info h2 {
  margin: 0;
}

.toc_list li {
  list-style-type: none;
}

.input-date {
  line-height: normal !important;
}
.svd_action.svd_state {
  display: none !important;
}

.svd-propertyeditor-condition div {
  /*min-width: -moz-fit-content !important;*/
  min-width: fit-content !important;
  max-width: none !important;
}

.sv_technical.svda-add-new-item {
  display: none;
}

.sv_q_dd_select_wrapper::after {
  pointer-events: none;
}

.sv_q_dd_filter-string-input {
  background-color: transparent !important;
  border: none !important;
}

.svd-items-control-footer .btn.sv-btn.btn-danger {
  display: none;
}

.svd_container .svd-page-scroller-arrow,
.svd_container .svd-pages {
  display: none !important;
}

.svd_container svd-pages-editor {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.svd_container svd-pages-editor .svd-page.svd-light-border-color span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8vw;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .input-date {
    line-height: normal !important;
  }
  .form-control {
    line-height: normal !important;
  }
}

.svd-json-editor-area {
  background-color: #282c34;
  color: #e7e7e7;
  padding: 1em;
  border-radius: 5px;
  font-family: monospace;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.43;
}

.json-editor-btn {
  margin-bottom: 1rem;
  font-size: 13px;
  color: #22333b;
  font-family: monospace;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 5px 10px;
}
